<template>
    <li :class="['page-item medium' , {'active' : isActive }]">
        <a v-if="page === null" class="page-link" type="button" :class="$style.centerIcons">
            ...
        </a>
        <a
            v-else
            class="page-link"
            type="button"
            :aria-label="`Go to page ${page}`"
            :class="{ 'page-link current': isActive }"
            @click="clickHandler"
        > 
            {{ page }}
        </a>
    </li>
</template>

<script>
    import { computed } from 'vue';
    export default {
        name: 'VPage',
        props: {
            page: {
                type: Number,
                default: null,
            },
            current: {
                type: Number,
                default: 0,
            },
            activeColor: {
                type: String,
                default: '#DCEDFF',
            },
        },
        emits: ['update'],
        setup(props, { emit }) {
            const isActive = computed(() => {
                return props.page === props.current;
            });
            function clickHandler() {
                emit('update', props.page);
            }

            return { isActive, clickHandler };
        },
    };
</script>

<style module>
    .centerIcons{
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
</style>