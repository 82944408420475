import Api from '@/services/Api';

export default {
  fetchPromotionsShock(params) {
    return Api().get(`/4.0/PromotionsShock`, { params });
  },

  fetchPromotionShockDetail(id, params = {}) {
    return Api().get(`/4.0/PromotionsShock/${id}`, { params });
  },
}