<template>
    <ul class="pagination d-flex" :class="wrapperClass">
        <!-- <li v-if="!hideFirstButton" class="page-item medium">
            <a 
                class="Control page-link prev"
                :class="{ 'Control-active': isPrevControlsActive }"
                @click="goToFirst"
            >
                &lt;&lt;
            </a>
        </li> -->
        <li class="page-item medium">
            <a 
                class="Control page-link"
                :class="[{ 'Control-active': isPrevControlsActive }, $style.centerIcons]"
                @click="goToPrev"
            >
                <i class="dsi dsi-chevron-left" :class="{ 'dsi-s24' : layout != 4}"></i>
            </a>
        </li>

        <v-page
            v-for="page in pagination"
            :key="`pagination-page-${page}`"
            :page="page"
            :current="modelValue"
            :active-color="activeColor"
            @update="updatePageHandler"
        />

        <li class="page-item medium">
            <a
                class="Control page-link"
                :class="[{'Control-active': isNextControlsActive },$style.centerIcons]"
                @click="goToNext"
            >
                <i class="dsi dsi-chevron-right" :class="{ 'dsi-s24' : layout != 4}"></i>
            </a>
        </li>
        <!-- <li v-if="!hideLastButton" class="page-item medium">
            <a
                class="Control"
                :class="{ 'Control-active': isNextControlsActive }"
                @click="goToLast"
            > >> </a>
        </li> -->
    </ul>
</template>

<script>
    import {  computed } from 'vue';
    import VPage from '@/components/pagination/VPage';
   
    export default {
        name: 'VPagination',
        components: { VPage },
        props: {
            wrapperClass: {
                type :String ,
                default : ''
            },
            pages: {
                type: Number,
                default: 0,
            },
            rangeSize: {
                type: Number,
                default: 1,
            },
            modelValue: {
                type: Number,
                default: 0,
            },
            activeColor: {
                type: String,
                default: '#DCEDFF',
            },
            hideFirstButton: {
                type: Boolean,
                default: false,
            },
            hideLastButton: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['update:modelValue'],
        setup(props, { emit }) {
            // pagination
            const pagination = computed(() => {
                const res = [];
                const minPaginationElems = 5 + props.rangeSize * 2;
                let rangeStart = props.pages <= minPaginationElems ? 1 : props.modelValue - props.rangeSize;
                let rangeEnd =
                    props.pages <= minPaginationElems ? props.pages : props.modelValue + props.rangeSize;
                rangeEnd = rangeEnd > props.pages ? props.pages : rangeEnd;
                rangeStart = rangeStart < 1 ? 1 : rangeStart;
                if (props.pages > minPaginationElems) {
                    const isStartBoundaryReached = rangeStart - 1 < 3;
                    const isEndBoundaryReached = props.pages - rangeEnd < 3;
                    if (isStartBoundaryReached) {
                        rangeEnd = minPaginationElems - 2;
                        for (let i = 1; i < rangeStart; i++) {
                            res.push(i);
                        }
                    } else {
                        res.push(1);
                        res.push(null);
                    }
                    if (isEndBoundaryReached) {
                    rangeStart = props.pages - (minPaginationElems - 3);
                    for (let i = rangeStart; i <= props.pages; i++) {
                        res.push(i);
                    }
                    } else {
                    for (let i = rangeStart; i <= rangeEnd; i++) {
                        res.push(i);
                    }
                        res.push(null);
                        res.push(props.pages);
                    }
                } else {
                    for (let i = rangeStart; i <= rangeEnd; i++) {
                        res.push(i);
                    }
                }
                return res;
            });

            function updatePageHandler(params) {
                emit('update:modelValue', params);
            }
            // controls
            const isPrevControlsActive = computed(() => {
                return props.modelValue > 1;
            });
            const isNextControlsActive = computed(() => {
                return props.modelValue < props.pages;
            });
            function goToFirst() {
                if (isPrevControlsActive.value) {
                    emit('update:modelValue', 1);
                }
            }
            function goToPrev() {
                if (isPrevControlsActive.value) {
                    emit('update:modelValue', props.modelValue - 1);
                }
            }
            function goToLast() {
                if (isNextControlsActive.value) {
                    emit('update:modelValue', props.pages);
                }
            }
            function goToNext() {
                if (isNextControlsActive.value) {
                    emit('update:modelValue', props.modelValue + 1);
                }
            }
            return {
                pagination,
                updatePageHandler,
                isPrevControlsActive,
                isNextControlsActive,
                goToFirst,
                goToLast,
                goToPrev,
                goToNext,
            };
        },
    };
</script>

<style module>
    .centerIcons{
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
</style>