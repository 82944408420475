<template>
    <Skeleton 
        :wrapperClass="wrapperClass"
        :gridClass="gridClass"
        ref="skeleton"
        :number="parseInt(options.slidesPerView)"
        v-if="brands.length === 0" 
    />
    
    <div v-else :class="wrapperClass">
        <div class="section-title-wrapper">
            <h2 class="section-title" v-text="title"></h2>
            
            <div class="dosiin_slider-controls" id="swiper-paginate">
                <div class="dosiin_slider-pagination swipable-slider_pagination swiper-button-pagination swiper-filter-nav-bar" :class="paginationClass"></div>
            </div>
        </div>

        <div class="dosiin_position-relative">
            <Swiper 
                class="overflow" :class="layout != 4 ? 'dosiin_position-unset' : '' "
                :modules="modules"
                :slidesPerView="options.slidesPerView"
                :slidesPerGroup="options.slidesPerGroup"
                :spaceBetween="options.spaceBetween"   
                :navigation="options.navigation"
                :pagination="pagination"
            >
                <SwiperSlide v-for="(brand , index) in brands" :key="index">
                    <BrandItemVertical :brand="brand" /> 
                </SwiperSlide>

                <div v-if="options.navigation" class="swiper-button-next dosiin_swiper-button dosiin_position-button-next"></div>
                <div v-if="options.navigation" class="swiper-button-prev dosiin_swiper-button dosiin_position-button-prev"></div>
                    
            </Swiper>
        </div>

        <div v-if="showLink" class="seemore-btn more">
            <router-link class="primary-button button" :to="link" :title="title">
                <span class="primary-link_text">Xem tất cả</span>
                <i class="dsi dsi-act-arrow-forward"></i>
            </router-link>
        </div>
    </div>
</template>

<script>
    import { toRefs , ref ,watch} from 'vue';
    import BrandItemVertical from '@/components/brand/BrandItemVertical';
    import Skeleton from '@/components/skeletons/sliders/ProductsHorizon';
    import { Pagination , Navigation } from 'swiper';
    export default {
        components:{
            BrandItemVertical,
            Skeleton
        },
        props:{
            title : {
                type : String, 
                default : ''
            },
            wrapperClass :{
                type : String, 
                default : ''
            },
            gridClass:{
                type : String, 
                default : 'grid-50'
            },
            brands :{
                type : Array,
                require : true,
                default : []
            },
            showLink :{
                type : Boolean, 
                default : false
            },
            showSlideLink :{
                type : Boolean, 
                default : false
            },
            link :{
                type : String, 
                default : '/'
            },
            options :{
                type : Object,
                default : {
                    slidesPerView : 2.5,
                    slidesPerGroup : 3,
                    spaceBetween   :9,
                    navigation: false,
                }
            }
        },
        setup(props) {
            const options = {};
            const skeleton = ref(null);
            const paginationClass = "dosiin_slider-pagination-" + btoa(Math.random().toString()).substr(10, 5);
            const pagination =  {
                el: '.' + paginationClass,
                clickable: true,
                renderBullet: (index, className) => `<span data="${index}" class="swipable-slider_indicator ${className}"></span>`,
            };
            
            return {
                options,
                skeleton,
                paginationClass,
                ...toRefs(props),
                pagination,
                modules:[Pagination , Navigation]
            }
        },
    }
</script>