// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VPage_centerIcons_SNSb4{display:flex!important;align-items:center;justify-content:center}", ""]);
// Exports
exports.locals = {
	"centerIcons": "VPage_centerIcons_SNSb4"
};
module.exports = exports;
